export const processText = (text: string):string => {
  // Alien language. Don't touch if you dunno how to deal with it lmao
  return text
    // Discards non-alphanumeric chars
    .replace(/[^\da-z\s\-.,]/gmi, "")
    // Discards duplicate ,, .. and --
    .replace(/-{2,}/gmi, "-")
    .replace(/,{2,}/gmi, ",")
    .replace(/\.{2,}/gmi, ".")
    // Discards repeating chars more than thrice, e.g: aaaaa wwww
    .replace(/([a-z])\1{3,}/gmi, "")
    // Discards word that do not have vocals more than 4 characters, e.g: ghftw
    .replace(/\b[^aeiou\s\d]{5,}\b/gmi, "")
    // Discards word that is more than 15 characters
    .replace(/\b[^\d\s]{16,}\b/gmi, "")

    // Replaces multiple spaces with single whitespace
    .replace(/ {2,}/gmi, " ")
    // Replaces multiple newline with single newline
    .replace(/\n{2,}/gmi, "\n")
    // Replaces leading whitespace in a line
    .replace(/^ +/gmi, "")
    // Replaces trailing whitespace in a line
    .replace(/ +$/gmi, "")
}