const processImg = (cv: any, img: HTMLCanvasElement): void => {
  let src = cv.imread(img)

  // Scale down image if it's big to improve performance
  const pixel = Number(process.env.REACT_APP_OPENCV_RESIZE_IMAGE_TO)
  if (img?.width > pixel || img?.height > pixel) {
    const aspectRatio = img?.width / img?.height
    const size = img?.height > img?.width ?
      new cv.Size(Math.floor(pixel * aspectRatio), pixel) :
      new cv.Size(pixel, Math.floor(pixel / aspectRatio))
    cv.resize(src, src, size, 0, 0, cv.INTER_AREA);
  }
  // Convert to gray scale
  let gray = new cv.Mat()
  cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY, 0)
  // Blur image to remove noise and texture
  cv.GaussianBlur(gray, gray, new cv.Size(15, 15), 0, 0, cv.BORDER_DEFAULT)
  // Convert to binary image
  cv.threshold(gray, gray, 0, 255, cv.THRESH_BINARY_INV + cv.THRESH_OTSU)

  // Get rid of text and extra texture from background
  let M = cv.Mat.ones(20, 20, cv.CV_8U)
  for(let i=0; i<5; i++) {
    cv.erode(gray, gray, M)
    cv.dilate(gray, gray, M)
  }
  cv.bitwise_not(gray, gray)

  // Find paper edges
  let contours = new cv.MatVector()
  let hierarchy = new cv.Mat()
  cv.findContours(gray, contours, hierarchy, cv.RETR_CCOMP, cv.CHAIN_APPROX_SIMPLE)
  let index_max = 0;
  let max = 0;
  for(let i=0; i<contours.size(); i++) {
    let val = cv.contourArea(contours.get(i), false)
    if (val > max) {
      max = val
      index_max = i
    }
  }

  // Crop the extra background
  let rect = cv.boundingRect(contours.get(index_max))
  let rect2 = new cv.Rect(rect.x, rect.y, rect.width, rect.height)

  cv.imshow(img, src.roi(rect2))

  src.delete()
  gray.delete()
  M.delete()
  contours.delete()
  hierarchy.delete()
}

// @ts-ignore
export const loadOpenCv = async (img: HTMLCanvasElement): Promise<void> => {
  new Promise(function (resolve) {
    try {
      // @ts-ignore
      const cv = window.cv
      if (cv) {
        processImg(cv, img)
        resolve("Success")
      } else {
        throw "CV is undefined."
      }
    } catch (e) {
      console.log(e)
    }
  })
}