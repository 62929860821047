import { createWorker } from "tesseract.js";

const getLangPath = () => {
  switch(process.env.REACT_APP_TESSERACT_OCR_MODE?.toUpperCase()) {
    case "BEST": return process.env.REACT_APP_TESSERACT_DATA_BEST_PATH;
    case "FAST": return process.env.REACT_APP_TESSERACT_DATA_FAST_PATH;
    default: return process.env.REACT_APP_TESSERACT_DATA_PATH;
  }
}

export const loadWorker = async () => {
  return await createWorker("eng", 1, {
    langPath: getLangPath()
  })
}