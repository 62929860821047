import * as React from "react"
import { ChangeEvent, useEffect, useState } from "react"
import { Box, Button, ChakraProvider, Grid, Input, Stack, Text, Textarea, theme } from "@chakra-ui/react"
import { ColorModeSwitcher } from "../ColorModeSwitcher"
import { loadOpenCv } from "../utils/imageProcessor"
import { processText } from "../utils/textProcessor"
import { loadWorker } from "../utils/tesseractWorkerLoader"

export const OcrTest = () => {
  const [fileData, setFileData] = useState<Blob>()
  const [fileLoading, setFileLoading] = useState<boolean>(false)
  const [result, setResult] = useState<string>("")

  let fileUpload: HTMLInputElement | null
  const data = new FileReader()

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]

      setFileLoading(true)

      data.readAsArrayBuffer(file)
      data.onload = (event) => {
        setFileData(new Blob([event.target?.result as ArrayBuffer]))
      }
    }
  }

  const runOcr = async () => {
    // Process image with openCV
    // @ts-ignore
    const ibm = await createImageBitmap(fileData)
    // @ts-ignore
    const img = document.getElementById("img") as HTMLCanvasElement
    img.width = ibm.width
    img.height = ibm.height
    img?.getContext('2d')?.drawImage(ibm, 0, 0)
    await loadOpenCv(img)

    // Put to tesseract
    const worker = await loadWorker()

    // @ts-ignore
    img?.toBlob(async (blob) => {
      const data = await worker.recognize(blob as Blob)
      const result = data?.data?.text || ""
      // Process text
      setResult(processText(result))
      await worker.terminate()
      setFileLoading(false)
    })
  }

  useEffect(() => {
    if (fileData) {
      runOcr()
    }
  }, [fileData]);

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
            <Stack direction="row" height="30vh" justifyContent="center">
              <Stack w="30wh">
                <Text fontSize={12}>This website is under development and testing<br/>Version {process.env.REACT_APP_VERSION}</Text>
                <Input ref={input => fileUpload = input}
                       type="file" accept="image/*"
                       display="none" onChange={onChangeFile} />
                <Button
                  bg={"green.500"}
                  color={'white'}
                  _hover={{
                    bg: "green.200",
                  }}
                  onClick={() => fileUpload?.click()}
                  loadingText="Processing..."
                  isLoading={fileLoading}
                >
                  Select Photo
                </Button>
              </Stack>
            </Stack>
          <Textarea display={result.length === 0 ? "none" : "current"} value={result} rows={15} readOnly/>
          <canvas id="img" style={{/*"display": "none",*/ "overflow": "auto"}} />
        </Grid>
      </Box>
    </ChakraProvider>
  )
}
